<template>
  <div class="global-loader-wrap" :style="{ opacity: loaderOpacity }">
    <div v-if="!showAnimation" class="whiteBg"></div>
    <div v-else class="lottie-animation_wrap centered">
      <div class="animation-container">
        <div
          ref="lottieContainer"
          class="lottie-animation"
          :class="{ 'transition-fade': true, show: showAnimation }"
          :style="{
            'max-width':
              loaderAnimationType === 'bank' || loaderAnimationType === 'de' 
                ? '350px'
                : loaderAnimationType === 'indiaLoader' ? '320px' 
                  : '160px',
          }"
        ></div>
        <transition name="fade" appear>
          <h2
            v-if="loaderAnimationText && showAnimationText"
            ref="loaderText"
            class="text-centered loader-text header-2"
            :style="{
              'margin-top':
                loaderAnimationType === 'bank' ||
                loaderAnimationType === 'de' || loaderAnimationType === 'indiaLoader'
                  ? '0px'
                  : '-16px !important',
            }"
          >
            {{ loaderAnimationText }}
          </h2>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import lottie from 'lottie-web';
export default {
    name: 'GlobalLoader',
    data() {
        return {
            isLoadingAnimation: false,
            showAnimation: false,
            showAnimationText: false,
            currentAnimation: null,
            DELoadingText: [
                'Hold tight, we are checking your details...',
                'Running our last few checks...',
                'Almost done, just setting your credit limit...',
            ],
            DERRLoadingText: [
                'Hold tight, we are checking your details...',
                'Running our last few checks...',
                'Almost done, just looking for your rent transactions...',
            ],
            currentIndex: 0,
        };
    },
    computed: {
        loaderAnimationType() {
            return this.$store.getters['navigation/loaderAnimationType'];
        },
        loaderAnimationText() {
            return this.$store.getters['navigation/loaderAnimationText'];
        },
        loaderOpacity() {
            return this.$store.getters['navigation/loaderOpacity'] ?? 1;
        },
        animationBank() {
            return this.$store.state.navigation.loaderProviderId;
        },
    },
    watch: {
        loaderAnimationType(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.loadAnimation();
            }
        },
    },
    unmounted() {
        document.body.style.overflow = 'auto';
        this.$store.dispatch('navigation/updateLoaderAnimationText', null);
        this.$store.dispatch('navigation/updateLoaderAnimationType', 'default');
        this.$store.dispatch('navigation/changeLoaderOpacity', 1);
    },

    mounted() {
        
        // If there is an html loader, remove it from DOM
        const preloader = document.getElementById('preloader');

        if (preloader) {
            preloader.remove();
        }
        document.body.style.overflow = 'hidden';
        this.loadAnimation();
        this.showAnimation = true;

        this.$nextTick(() => {
            setTimeout(() => {
                this.showAnimationText = true;
                this.$nextTick(() => {
                    const loaderText = this.$refs.loaderText;
                    if (loaderText) {
                        const height = loaderText.offsetHeight; // Get the height of the loader text
                        const lottieAnimation = this.$refs.lottieContainer;
                        if (lottieAnimation) {
                            lottieAnimation.style.marginTop = `-${
                                height + 30
                            }px`; // Set the negative margin-top
                        }
                    }
                });
            }, 1);
        });
    },

    beforeUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    },
    methods: {
        changeString() {

            const applicationId = localStorage.getItem('applicationId');
            let applicationData = null;
            let product = null;

            if(applicationId) {
                applicationData = this.$store.getters['application/applicationData'];
            }

            if (applicationData) {
                product = applicationData.product;
                localStorage.setItem('productName', product);
            } else {
                product = localStorage.getItem('productName');
            }

            if (product === 'rentReportingAndMonitor') {
                const newString = this.DERRLoadingText[this.currentIndex];
                this.$store.dispatch(
                    'navigation/updateLoaderAnimationText',
                    newString
                );
                this.currentIndex =
                    (this.currentIndex + 1) % this.DERRLoadingText.length;
            } else {
                const newString = this.DELoadingText[this.currentIndex];
                this.$store.dispatch(
                    'navigation/updateLoaderAnimationText',
                    newString
                );
                this.currentIndex =
                    (this.currentIndex + 1) % this.DELoadingText.length;
            }
        },

        async loadAnimation() {
            if (this.isLoadingAnimation) return; // Prevent concurrent loads
            this.isLoadingAnimation = true; // Set loading state

            if (this.currentAnimation) {
                this.currentAnimation.destroy();
                this.currentAnimation = null;
            }
            let animationData;

            if (this.loaderAnimationType == 'bank') {
                this.$store.dispatch(
                    'navigation/updateLoaderAnimationText',
                    'Securely connecting to your bank account. This should only take a moment.'
                );

                try {
                    animationData = await import(
                        `../../../public/lottie-animation/banks/${this.animationBank}.json`
                    );
                    animationData
                } catch (err) {
                    animationData = await import(
                        `../../../public/lottie-animation/banks/default.json`
                    );
                }
            } else if (this.loaderAnimationType == 'de') {
                animationData = await import(
                    `../../../public/lottie-animation/decision-engine.json`
                );

                this.changeString();
                this.interval = setInterval(this.changeString, 10000);
            } else if (this.loaderAnimationType == 'indiaLoader') {
                animationData = await import(
                    `../../../public/lottie-animation/india-loader.json`
                );
            }else {
                animationData = await import(
                    `../../../public/lottie-animation/loader.json`
                );
            }

            const container = this.$refs.lottieContainer;

            // Load the animation without autoplay
            const animation = lottie.loadAnimation({
                container: container,
                renderer: 'svg',
                loop: true,
                autoplay: false, // Set autoplay to false
                animationData: animationData.default,
            });
            animation.setSpeed(1.3);
            // Pause the animation immediately
            animation.pause();

            // Delay before playing the animation

            this.showAnimation = true;
            setTimeout(() => {
                this.showAnimationText = true;
            }, 300);

            animation.play();
            this.currentAnimation = animation;
            this.isLoadingAnimation = false;

        },
    },
};
</script>

<style lang="scss">
.global-loader-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    z-index: 1000000;
    align-content: center;
    display: flex;
    align-content: center;
    justify-content: center;
    overflow: hidden;
    background-color: white;
}

.animation-container {
    position: relative;
    width: 100%;
}
.lottie-animation {
    width: 100%;
    margin: 0 auto;
    margin-top: -20px;
}

.whiteBg {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 20000;
}

.lottie-animation_wrap {
    width: 100%;
    max-width: $max-content-width;
}

.consentComplete_title {
    padding-left: 16px !important;
    padding-right: 16px !important;
}

.transition-fade {
    opacity: 0;
    transition: opacity 0.5s;
}

.transition-fade.show {
    opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
    transition-delay: 0.1s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.loader-text {
    position: absolute;
    // bottom: -20px;
    // padding-left: 32px !important;
    // padding-right: 32px !important;

    justify-content: center;
    // margin-left: auto !important;
    // margin-right: auto !important;

    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;

    // @media (min-width: 1024px) {
    //   max-width: 320px !important;

    // }
}
</style>
